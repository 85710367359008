import '../assets/css/userprofile.css';

import { navigate } from 'gatsby';
import React from 'react';


import Layout from '../components/layout';
//import LeftProfile from '../components/leftProfile';
import SEO from '../components/seo';

import { setCredentials } from './../core/auth';
import { isAuthenticated,getAuthenticatedUser } from '../core/util';
import chatbottom from '../images/chat-bottom-icon.svg';
import chatProfile from '../images/chat-profile.png';
import closeChat from '../images/close-icon.svg';
import handIcon from '../images/hand.svg';
import dollarIcon from "../images/profile/dollar.svg"
import copyIcon from "../images/profile/copy.svg"
import timeIcon from "../images/profile/timezone.svg"

import Formsy from 'formsy-react';
import MyInput from '../components/MyInput';



import loaderBlack from "../images/profile/loaderBlack.svg"
import closeIcon from "../images/profile/close-button.svg"
import downloadIcon from "../images/profile/download.svg"
import arrowDown from '../images/arrow-down.svg';
import repairpal from "../images/profile/repairpal.png"


import EditIcon from '../images/Edit-icon.svg';
import AddressImg from '../images/Profile/address-icon.svg';
import PhoneImg from '../images/Profile/call-icon.svg';
import EmailImg from '../images/Profile/email.svg';
import { Link } from "gatsby";

// import Formsy from 'formsy-react';
// import newslatter from "../images/newslatter.svg"


//import contactcloud from "../images/contacts/cloud.svg"
// import { Link } from "gatsby";

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            profile: false,
            contracts: null,
            showalert:0,
            curindx:0,
            userToken:null,
            alertMsg:null,
            alertType:null,
            actions : [],
            dealertoken:null,
            srchtxt:""
        };
    }

    async componentDidMount() {
        let dealertoken = this.getDealerToken();
        console.log(dealertoken,"-----------------")
        if (!isAuthenticated()) {
            navigate("/login"+(dealertoken?'/?dealertoken='+dealertoken:''));
        } else {
            let usrdata = getAuthenticatedUser();
            this.setState({ userToken: usrdata.token })
            await this.getFromDirect(usrdata.token, dealertoken);
        }
        
    }

    getDealerToken = () => {
        if(global.dealertoken){
            this.setState({ dealertoken: global.dealertoken })
            return global.dealertoken;
        } else {
            let data = this.props.location.search;
            if (data !== '') {
                const search = data.substring(1);
                let param = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
                let dt = param.dealertoken ? param.dealertoken : null;
                this.setState({ dealertoken: dt });
                global.dealertoken = dt;
                return dt;
            } else {
                this.setState({ dealertoken: null });
                global.dealertoken = null;
                return null;
            }
        }
    }

    getFromDirect = async(paramId, dealertoken) => {
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getProfile'+(dealertoken?'/?dealertoken='+dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: paramId,
                })
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    this.setState({ profile: res.me, dealertoken:res.dealertoken })
                    global.dealertoken = res.dealertoken;
                    var actions = {};
                    Object.keys(res.plans).map(function(char, idx) {
                        actions[idx]={'down':0,'cancel':0,'pay':0,'showtran':0,trans:null}
                    });
                    this.setState({ actions: actions })
                    this.setState({ contracts: res.plans})
                } else {
                    setCredentials(null);
                    navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                }
            });
    }


    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = (model) => {
        console.log(model);
    }

    b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
          
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }
    
    dateFormat = (isodt) => {
        //let date = new Date(isodt)
        /* let dt = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
        let tim = date.getHours()+ ':' + date.getMinutes() + ':'+date.getSeconds();
        return dt+' '+tim; */

        let date_ob = new Date(isodt);
        console.log(date_ob);
        console.log(date_ob.getHours() +'-'+date_ob.getMinutes() +'-'+date_ob.getSeconds());

        // current date
        // adjust 0 before single digit date
        let date = ("0" + date_ob.getDate()).slice(-2);

        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

        // current year
        let year = date_ob.getFullYear();

        // current hours
        let hours = ("0" + (date_ob.getHours())).slice(-2);

        // current minutes
        let minutes = ("0" + (date_ob.getMinutes())).slice(-2);

        // current seconds
        let seconds = ("0" + (date_ob.getSeconds())).slice(-2);

        
        //return (year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds);
        return (year + "-" + month + "-" + date);
    }

    searchme = () => {
        var {contracts, srchtxt, actions} = this.state;
        srchtxt = srchtxt.toString().toLowerCase();
        for(var i=0; i<contracts.length; i++) {
            let avl = 0;
            if(srchtxt == ""){
                avl =1;   
            } else {
                for(var k in contracts[i]) {
                    let txt = contracts[i][k].toString().toLowerCase();
                    if(txt.includes(srchtxt)){
                        avl =1;
                        break;
                    }
                }
            }
            if(avl){
                contracts[i]['mainview'] = 1;
            } else {
                contracts[i]['mainview'] = 0;
            }
            contracts[i]['show'] = 0;
            actions[i]['showtran'] = 0;
            
        }
        console.log(contracts)
        this.setState({ contracts: contracts, actions:actions });
    }

    showInfo = (idx) => {
        var {contracts, actions} = this.state;
        var contracts = this.state.contracts;
        if(contracts[idx]['show'])
            contracts[idx]['show'] = 0;
        else
            contracts[idx]['show'] = 1;
        
        actions[idx]['showtran'] = 0;
        
        this.setState({ contracts: contracts,actions:actions });
        
    }

    pdfdown = (contractId, idx,recordId) => {
        var tmpact = this.state.actions;
        tmpact[idx]['down'] = 1;
        this.setState({ actions: tmpact });
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getpdf'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
          fetch(uri,{
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({
                contractId: contractId,
                recordId:recordId
              })
            })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    const contentType = 'application/pdf';
                    const blob = this.b64toBlob(res.data, contentType);
                    var blobUrl = window.URL.createObjectURL(blob);
                    a.href = blobUrl;
                    a.download = 'contract.pdf';
                    a.click();
                    window.URL.revokeObjectURL(blobUrl);
                    tmpact[idx]['down'] = 0;
                    this.setState({ actions: tmpact });
                } else {
                    tmpact[idx]['down'] = 0;
                    this.setState({ actions: tmpact });
                }
          });
    }

    closeAlert = () => {
        var tmpact = this.state.actions;
        tmpact[this.state.curindx]['cancel'] = 0;
        this.setState({ actions:tmpact,showalert: 0 });
    }
    showAlert = (idx,msg,typ) => {
        var tmpact = this.state.actions;
        tmpact[idx]['cancel'] = 1;
        this.setState({ actions:tmpact,showalert:1,curindx:idx,alertMsg:msg,alertType:typ});
    }
    cancelContract = () => {
        this.setState({ showalert: 0 });
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/voidContract'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        var curidx = this.state.curindx;
        fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                contractId: this.state.contracts[curidx]['_id'],
                token:this.state.userToken
            })
        })
        .then(res => res.json())
        .then((res) => {
            if(res.status === -1){
                setCredentials({});
                navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
            } else if(res.status === 0){
                this.showAlert(this.state.curindx,'Failed to void the contract','error')
            } else {
                var contracts = this.state.contracts;
                contracts[curidx]['contractStatus'] = 0;
                this.setState({contracts:contracts});
            }
        });
    }

    payList = (_id,idx) => {
        var tmpact = this.state.actions;
        tmpact[idx]['pay'] = 1;
        tmpact[idx]['showtran']= 0;
        tmpact[idx]['trans']= null;
        this.setState({ actions: tmpact });

        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/payList'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        //var curidx = this.state.curindx;
        fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                _id: _id,
                token:this.state.userToken
            })
        })
        .then(res => res.json())
        .then((res) => {
            if(res.status === -1){
                setCredentials({});
                navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
            } else if(res.status === 0){
                tmpact[idx]['showtran']= 1;
                tmpact[idx]['trans']= null;
            } else {
                tmpact[idx]['showtran']= 1;
                tmpact[idx]['trans']= res.trans;
            }
            tmpact[idx]['pay'] = 0;
            console.log(idx);
            console.log(tmpact);
            this.setState({ actions: tmpact });
        });
    }

    

    render() {
        const { profile,contracts,actions,showalert,alertMsg,alertType,dealertoken,srchtxt } = this.state;
        if(!contracts) return '';
        console.log(dealertoken,'---------')
        return (
            
            
            <Layout layoutType={dealertoken?'nohead':"profileHeader"}>
                <SEO title="Servicecontract.com - My Profile" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                <div className="chat-content" style={{ display: 'none' }}>
                    <div className="chat-content-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                            <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                                <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                                    <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="chat-box">

                        <div className="chatbox-inner">
                            <div className="chat-profile">
                                <img className="" src={chatProfile} alt="" />
                            </div>
                            <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
                            <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
                        </div>
                        <img className="chat-bottom" src={chatbottom} alt="" />
                    </div>

                </div>
                {showalert ?
                <div className="success-container">
                    <div className="success-inner small">
                        <div onClick={() => this.closeAlert()} className="close-btn close">
                            <img className="" src={closeChat} alt="" />
                        </div>

                        <div className="content">
                            <div className="sub-text">
                            <p className="subtextp">{alertMsg}<br /><br /></p>
                            </div>
                            {
                                alertType==='info' ?
                                <div onClick={() => this.cancelContract()} className="button">
                                    <button className="btn">OK</button>
                                </div>
                                :
                                <div onClick={() => this.closeAlert()} className="button">
                                    <button className="btn">OK</button>
                                </div>
                            }
                            

                        </div>
                    </div>
                </div>
                :''}

                

                {/* --------------banner--------- */}

                <section className={dealertoken?"user-profile-section grayish":"user-profile-section"}>
                    <div className="container">
                        <div className="user-profile">
                            <div className="left-side" >
                                <div className="card">
                                    <h3>Your Account</h3>
                                    <div className="inner-card">
                                        <div className="Edit-content">
                                            <Link to={"/profileedit"+(global.dealertoken?'/?dealertoken='+global.dealertoken:'')}>
                                                <span>Edit</span>  <img src={EditIcon} alt="" />
                                            </Link>
                                        </div>
                                        <div className="profile-head">
                                            {/* <div className="profile-dp">
                                                <img src={ProfileImg} alt="" />
                                            </div> */}
                                            <p> </p>
                                            <span>Account Holder</span>
                                            <p>{profile.firstName} {profile.lastName}</p>
                                        </div>
                                        <div className="profile-details">
                                            <div className="email sub-details">
                                                <div className="icon">
                                                    <img src={EmailImg} alt="" />
                                                </div>
                                                <div className="details">
                                                    <span>Email</span>
                                                    <p>{profile.email}</p>
                                                </div>
                                            </div>
                                            <div className="sub-details">
                                                <div className="icon address-icon">
                                                    <img src={AddressImg} alt="" />
                                                </div>
                                                <div className="details">
                                                    <span>Street Address</span>
                                                    <p>{profile.address}<br /> {profile.city}, {profile.state} {profile.zipcode}</p>
                                                </div>
                                            </div>
                                            <div className="sub-details">
                                                <div className="icon phone-icon">
                                                    <img src={PhoneImg} alt="" />
                                                </div>
                                                <div className="details">
                                                    <span>Phone</span>
                                                    <p>{profile.phone}</p>
                                                </div>
                                            </div>
                                            <div className="sub-details">
                                                <div className="icon phone-icon">
                                                    <img src={timeIcon} alt="" width="16" />
                                                </div>
                                                <div className="details">
                                                    <span>Time Zone</span>
                                                    <p>PST – Pacific Standard Time</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="notification">
                                            <p>Notification</p> 
                                        </div> */}
                                        <div className="password">
                                            <p className="change-pass"><Link className="btn" to={"/change-password"+(global.dealertoken?'/?dealertoken='+global.dealertoken:'')}>Change Password</Link></p>
                                        </div>
                                        
                                    </div>
                                    <div className="profile-details nobtm">
                                            <div className="email sub-details">
                                                <div className="icon">
                                                    <img src={repairpal} alt="" width="80" />
                                                </div>
                                                <div className="details">
                                                    <a href="https://staging3.1800warranty.com/repairpalnew/?portcode=sc" className="repairbtn">Repair Shop</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div >
                            <div className="right-side">
                                                            
                            <div>
                            <div className="card">
                                <div className="inner-card srchnew">
                                    <div className="card-header">
                                        <div className="left">
                                        <Formsy ref="confirmForm">
                                        <MyInput
                                            name="coupon"
                                            type="text"
                                            placeholder="Enter a search string"
                                            value=""
                                            className="ccbox"
                                            valueChanges={(e) => this.setState({srchtxt:e})}
                                            />
                                        <button onClick={() => {this.searchme()}} className={"ccbtn"}>Search</button>
                                        </Formsy>
                                        
                                        </div>
                                        <div className="right">
                                        <Link className="ccbtn newcont" to={"/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:'')}>
                                            New Contract
                                        </Link>
                                        </div>
                                    </div>
                                </div>
                            
                            {contracts && Object.keys(contracts).map(function(char, idx) {
                                return <>
                                {contracts[idx]['mainview']>0 &&
                                <div className="inner-card" key={contracts[idx]['contractId']} id={contracts[idx]['contractId']}>
                                    <div className="card-header">
                                        <div className="left">
                                            
                                            <div className="listitle" onClick={() => this.showInfo(idx)}>
                                                <img className="arowdwn" src={arrowDown} />
                                                {contracts[idx]['vehicle']}</div>
                                        </div>
                                        <div className="right">
                                            
                                                {(contracts[idx]['contractStatus']===1)?
                                                <div className="active-btn">
                                                <span className="green"></span>
                                                <label>Active</label>
                                                </div>
                                                :
                                                <div className="active-btn">
                                                <span className="red"></span>
                                                <label>Inactive</label>
                                                </div>
                                                }
                                            
                                        </div>
                                    </div>
                                    
                                    {contracts[idx]['show']>0 &&
                                    <div className="card-body">
                                        
                                        <div className="left">
                                            <div>
                                                <ul>
                                                    <li>
                                                        <div>
                                                            <span>COVERAGE PLAN</span>
                                                            <p>{contracts[idx]['levelOfCoverage']}</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <span>VIN</span>
                                                            <p>{contracts[idx]['vin']}</p>
                                                        </div>
                                                    </li>
                                                    
                                                    <li>
                                                        {!contracts[idx]['termMonth'] &&
                                                        <div>
                                                            <span>Deductible</span>
                                                            <p>${contracts[idx]['payAtClaimTime']}</p>
                                                        </div>
                                                        }

                                                        {/* {contracts[idx]['termMonth'] &&
                                                        <div>
                                                            <span>Months</span>
                                                            <p>{contracts[idx]['termMonth']}</p>
                                                        </div>
                                                        } */}
                                                    </li>
                                                    
                                                    <li className="last-li-setting">
                                                        <div>
                                                            <span>Amount</span>
                                                            <p className="amount-size">
                                                                ${contracts[idx]['monthlyPayment']+(contracts[idx]['dividendPrice']*1)+(contracts[idx]['SealsGaskets']?contracts[idx]['SealsGaskets']:0)}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    
                                                    <li className="last-li-setting">
                                                        <div>
                                                            <span>Discount</span>
                                                            <p className="amount-size">
                                                                ${contracts[idx]['discount']}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <span>Contract ID</span>
                                                            <p>{contracts[idx]['contractId']}</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <span>Started Plan</span>
                                                            <p>{contracts[idx]['startDate']}</p>
                                                        </div>
                                                    </li>
                                                    {contracts[idx]['cardNo']!="NA" ?
                                                    <li>
                                                        <div>
                                                            <span>Card </span>
                                                            {(contracts[idx]['contractStatus']===1 && !contracts[idx]['termMonth'])?
                                                                <img className="edicon" src={EditIcon} alt="" onClick={(e) => navigate("/cardedit"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''),{state: { contractId: contracts[idx]['_id'] }})} />
                                                                :''
                                                            }
                                                            <p>**** **** {contracts[idx]['cardNo']}</p>
                                                        </div>
                                                    </li>
                                                    :
                                                    <li>
                                                        <div>
                                                            <span>Payment </span>
                                                            <p>Offline</p>
                                                        </div>
                                                    </li>
                                                    }
                                                {(profile.dealerId || profile.dealAdmin) &&    
                                                <>
                                                    <li>
                                                        <div>
                                                            <span>First Name</span>
                                                            <p>{contracts[idx]['fn']}</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <span>Last Name</span>
                                                            <p>{contracts[idx]['ln']}</p>
                                                        </div>
                                                    </li>
                                                </>
                                                }
                                                <li>
                                                    <div>
                                                        <span>Email</span>
                                                        <p>{contracts[idx]['email']}</p>
                                                    </div>
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        { (contracts[idx]['contractStatus']===1)?
                                        <div id="detailsButton1" className="right">
                                            <div>
                                                <button className="down-btn" onClick={(e) => {this.pdfdown(contracts[idx]['contractId'],idx,contracts[idx]['_id'])}}>Download
                                                {actions[idx]['down']===0 ? <img className="btn-arrow" alt="Download" src={downloadIcon} /> : ''}
                                                {actions[idx]['down']===1 ? <img className="loader-spin-tiny" alt="loading" src={loaderBlack} /> : ''}
                                                </button>
                                            </div>

                                            {contracts[idx]['showvc']>0 &&
                                            <div>
                                                {contracts[idx]['canVoid']=='void' &&
                                                <button className="down-btn" onClick={(e) => navigate("/voidContract"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''),{state: { contractId: contracts[idx]['contractId'],vin: contracts[idx]['vin']}})}>Void 
                                                <img className="btn-arrow" alt="Void" src={closeIcon} />
                                                </button>
                                                }
                                                {contracts[idx]['canVoid']=='cancel' &&
                                                <button className="down-btn" onClick={(e) => navigate("/cancelContract"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''),{state: { contractId: contracts[idx]['contractId'],vin: contracts[idx]['vin']}})}>Cancel 
                                                <img className="btn-arrow" alt="Void" src={closeIcon} />
                                                </button>
                                                }
                                            </div>
                                            }

                                            <div>
                                                <button className="down-btn" onClick={(e) => navigate("/sendClaim"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''),{state: { contractId: contracts[idx]['contractId'] }})} >Claim
                                                <img className="btn-arrow" alt="Claim" src={copyIcon} /> 
                                                </button>
                                            </div>
                                            {!contracts[idx]['termMonth'] && contracts[idx]['cardNo']!="NA" &&
                                            <div>
                                                <button className="down-btn" onClick={(e) => {this.payList(contracts[idx]['_id'],idx)}}>Transactions 
                                                {actions[idx]['pay']===0 ? <img className="btn-arrow" alt="Trans" src={dollarIcon} /> : ''}
                                                {actions[idx]['pay']===1 ? <img className="loader-spin-tiny" alt="loading" src={loaderBlack} /> : ''}
                                                </button>
                                            </div>
                                            }
                                        </div>
                                        : '' }
                                    </div>
                                    }
                                    {actions[idx]['showtran'] ?
                                    <div className="footer-part">
                                        {actions[idx]['trans']?
                                        <table>
                                            <thead><tr><th>DATE</th><th>TRANSACTION ID</th><th>AMOUNT</th><th>STATUS</th></tr></thead>
                                            <tbody>
                                                {
                                                    Object.keys(actions[idx]['trans']).map(function(char2, idx2) {
                                                        return <tr key={actions[idx]['trans'][idx2]['transId']}>
                                                        <td>{actions[idx]['trans'][idx2]['tim']}</td>
                                                        <td>{actions[idx]['trans'][idx2]['transId']}</td>
                                                        <td>${actions[idx]['trans'][idx2]['amt']}</td>
                                                        <td>{actions[idx]['trans'][idx2]['status']}</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <table><thead><tr><th>No transaction available</th></tr></thead></table>
                                        }
                                    </div>
                                    :''}
                                </div>
                                }
                                </>;
                            }.bind(this))}
                            </div>
                        </div>

                            </div>
                        </div>
                    </div>


                </section>
                {!dealertoken &&
                <section className="copyright-container last-text userprofile-text">
                    <div className="container">
                        <div className="copyright">
                            <small>Copyright servicecontract.com © {new Date().getFullYear()}</small>
                        </div>
                    </div>
                </section>
                }


            </Layout >
        );
    }
}

export default ContactPage
